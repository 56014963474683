<template>
    <ul>
        <component
            :is="resolveNavItemComponent(item)"
            v-for="item in items"
            v-show="checkListPermission(item)"
            :key="item.header || item.title"
            :item="item"
        />
    </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { provide, ref } from '@vue/composition-api';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup() {
        provide('openGroups', ref([]));

        return {
            resolveNavItemComponent,
        };
    },
    computed: {
        fetchPermissions() {
            return this.$store.state.auth.user
                ? this.$store.state.auth.user.permissions
                : [];
        },
    },
    methods: {
        checkListPermission(item) {
            // console.log('list-item', item);
            if (item.title && this.fetchPermissions.length > 0) {
                const module = item.title.toLowerCase();
                switch (module) {
                    case 'home':
                        // case 'settings':
                        return true;
                    case 'admin users':
                        return this.$helper.checkUserPermission(
                            'navigation',
                            'users',
                            this.$store.state.auth.user.permissions,
                        );
                    case 'existing members':
                        return this.$helper.checkUserPermission(
                            'navigation',
                            'existinggcmembers',
                            this.$store.state.auth.user.permissions,
                        );
                    case 'notification templates':
                        return this.$helper.checkUserPermission(
                            'navigation',
                            'notificationtemplates',
                            this.$store.state.auth.user.permissions,
                        );

                    case 'cms':
                        return this.$helper.checkUserPermission(
                            'navigation',
                            'contents',
                            this.$store.state.auth.user.permissions,
                        );
                    default:
                        return this.$helper.checkUserPermission(
                            'navigation',
                            module,
                            this.$store.state.auth.user.permissions,
                        );
                }
            } else {
                return true;
            }
        },
    },
};
</script>
