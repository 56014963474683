export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'Home',
    route: { path: '/account/dashboard' },
    icon: 'HomeIcon',
  },
  {
    header: 'Roles & Permissions',
  },
  {
    title: 'Roles',
    route: { path: '/account/roles' },
    icon: 'UserPlusIcon',
  },
  {
    title: 'Users',
    route: { path: '/account/users' },
    icon: 'UserIcon',
  },
  {
    header: 'Modules',
  },
  {
    title: 'Grouped Orgs',
    route: { path: '/account/companies' },
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Customers',
    route: { path: '/account/clients' },
    icon: 'UsersIcon',
  },
  {
    title: 'Jobs',
    route: { path: '/account/jobs' },
    icon: 'ClipboardIcon',
  },
  {
    title: 'Classes & Wheel Layouts',
    route: { path: '/account/classes' },
    icon: 'SlidersIcon',
  },
  {
    title: 'Vehicles',
    route: { path: '/account/vehicles' },
    icon: 'TruckIcon',
  },
  {
    title: 'Notifications',
    route: { path: '/account/notifications' },
    icon: 'BellIcon',
  },
  {
    header: 'Reports',
  },
  {
    title: 'Approval Times',
    route: { path: '/account/approval-times' },
    icon: 'ClockIcon',
  },
  {
    header: 'Site Settings',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Settings',
        route: { path: '/account/settings' },
        icon: 'ApertureIcon',
      },
      {
        title: 'Services',
        route: { path: '/account/services' },
        icon: 'ToolIcon',
      },
      {
        title: 'Actions',
        route: { path: '/account/actions' },
        icon: 'DiscIcon',
      },
      {
        title: 'Modules',
        route: { path: '/account/modules' },
        icon: 'PackageIcon',
      },
      {
        title: 'Permissions',
        route: { path: '/account/permissions' },
        icon: 'UserPlusIcon',
      },
      {
        title: 'Removal Reasons',
        route: { path: '/account/removalreasons' },
        icon: 'AlertCircleIcon',
      },
    ],
  },
]
